export default {
  error_occurred: 'Ci scusiamo, si é verificato un errore. Si prega di riprovare tra un momento.',
  new_content_available: "C'è un nuovo aggiornamento della pagina, fai clic su 'Aggiorna' per ricaricare la pagina.",
  refresh_button_title: 'Ricaricare',
  force_reload_message: 'Spiacenti, si è verificato un errore. Il nostro team ci sta già lavorando. Prova a ricaricare il sito tra un momento.',
  sign_up: 'Creare un account',
  service: 'Servizio',
  employee: 'Dipendente',
  select_employee: 'Seleziona una persona',
  employees: 'Dipendenti',
  services: 'Servizi',
  duration: 'Durata',
  from: 'Da',
  select_date: 'Seleziona una data',
  no_account_question: 'Non hai ancora un conto?',
  name: 'Nome di battesimo',
  surname: 'Cognome',
  name_and_surname: 'Nome e cognome',
  phone: 'Telefono',
  password: "Parola d'ordine",
  field_is_required: 'Questo campo è obbligatorio.',
  value_is_too_long: 'Il valore è troppo lungo.',
  value_is_too_short: 'Il valore è troppo breve.',
  signup_was_successful: 'La registrazione è andata a buon fine. Accesso al pannello.',
  signup_has_failed: 'La registrazione non è riuscita. Si prega di riprovare tra un momento.',
  email_is_taken: "L'account con l'indirizzo email specificato esiste già. Se hai dimenticato la password, utilizza l'opzione promemoria.",
  email_or_password_incorrect: "L'indirizzo e-mail o la password non sono corretti. Se hai dimenticato la password, utilizza l'opzione promemoria.",
  account_not_found: 'Account non trovato.',
  page_not_found: 'Ops! Impossibile trovare la pagina che stavi cercando :(',
  go_back_to_main_page: 'Torna alla home page e riprova.',
  bookings: 'Prenotazioni',
  account: 'Il mio account',
  date: 'Data',
  cancel: 'Annulla',
  date_from: 'Data da',
  date_to: 'Data a',
  nothing_found_here: 'Non è stato trovato nulla.',
  old_password: 'Vecchia password',
  remove: 'Elimina',
  phone_is_invalid: 'Il numero di telefono non è corretto.',
  book: 'Prenotare',
  sign_up_2: 'Creare un account',
  log_in: 'Registrazione',
  your_email_address: 'Il tuo indirizzo di posta elettronica',
  select: 'Selezionare',
  filters: 'Filtri',
  expand: 'Espandere',
  collapse: 'Crollo',
  bookings_drawer_title: 'Seleziona i filtri per mostrare solo le prenotazioni che stai cercando.',
  clear_filters: 'Ripristina i filtri',
  show_more: 'Mostra di più',
  dont_you_remember_password: 'Hai dimenticato la password?',
  password_information: 'La password deve essere composta da almeno 8 caratteri, contenere una lettera maiuscola, un numero e un carattere speciale.',
  forgotten_password_button: 'Invia un link per cambiare la tua password',
  do_you_already_have_an_account: 'Hai già un account?',
  reset_password_title: 'Imposta nuova password',
  reset_password_subtitle: 'Inserisci la tua nuova password qui sotto.',
  reset_password_button: 'Salva la nuova password',
  profile: 'Profilo',
  logout: 'disconnessione',
  cancel_booking: 'Cancella la tua prenotazione',
  status_approved: 'Approvato',
  status_payment: 'In attesa del pagamento',
  status_done: 'Completato',
  status_cancelled: 'Annullato',
  cancel_booking_dialog_title: 'Vuoi cancellare la tua prenotazione?',
  cancel_booking_dialog_information: 'Questa azione non può essere annullata!',
  cancel_booking_dialog_button: 'Sì, cancella la mia prenotazione',
  my_profile: 'Il mio profilo',
  my_data: 'I miei dettagli',
  upload_new_photo: 'Aggiungi una nuova foto',
  birthday: 'Compleanno',
  save_changes: 'Salvare le modifiche',
  phone_number: 'Numero di telefono',
  directional: 'Prefisso',
  save_new_password: 'Salva la nuova password',
  new_password: 'Nuova password',
  password_change: 'Cambia la password',
  view: 'Visualizzazione',
  services_2: 'Servizi',
  accept_terms_and_conditions_1: "Cliccando sull'opzione qui sotto, accetto il",
  accept_terms_and_conditions_2: 'Termini e Condizioni',
  accept_terms_and_conditions_3: 'e confermo di aver letto il',
  accept_terms_and_conditions_4: 'Informativa sulla Privacy.',
  to: 'Per',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Registrazione',
  email_is_incorrect: "L'indirizzo email non è corretto.",
  logged_out: 'Sei stato disconnesso',
  booking_dialog_date_is_already_taken: "L'ora selezionata non è disponibile. Ti dispiacerebbe scegliere un posto diverso?",
  data_search: 'Ricerca di dati. Attendere prego...',
  no_employees: 'Nessun dipendente',
  no_services: 'Nessun servizio',
  no_categories: 'Nessuna categoria',
  no_bookings: 'Prenotazioni non trovate.',
  no_subscriptions: 'Non sono stati trovati abbonamenti.',
  female: 'Femmina',
  male: 'Maschio',
  other: 'Altro',
  no_numeric_in_password: 'Nessun carattere numerico nella password.',
  no_lowercase_in_password: 'Nessuna lettera minuscola nella password.',
  no_uppercase_in_password: 'Nessuna lettera maiuscola nella password.',
  no_special_character: 'Nessun carattere speciale.',
  min_n_characters: 'Il numero minimo di caratteri è @{min}.',
  max_n_characters: 'Il numero massimo di caratteri è @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'La password non è stata modificata. Probabilmente il collegamento è scaduto.',
  reset_password_success_title: 'La tua password è stata modificata.',
  reset_password_success_text: 'Puoi accedere con la tua nuova password.',
  account_activation: "Attivazione dell'account",
  account_activation_success: "L'account è stato attivato.",
  account_activation_fail: "Si è verificato un problema durante l'attivazione del tuo account. Sembra che l'account sia già attivo o che il collegamento non sia più valido.",
  account_activation_processing: 'Attivazione del tuo account. Attendere prego...',
  account_activation_resend_title: "L'account non è stato attivato",
  account_activation_resend_text: "Attiva il tuo account facendo clic sul link di attivazione nell'e-mail che ti abbiamo inviato.",
  account_activation_resend_button: 'Invia di nuovo',
  confirm_send_activate_link_dialog_subtitle: "Abbiamo inviato un'e-mail con il link di attivazione all'indirizzo e-mail fornito in precedenza.",
  file_is_too_large: 'Il file selezionato è troppo grande.',
  appointment_title: "Seleziona una persona, la data e l'ora della prenotazione",
  others: 'Altro',
  countryLabel: 'Paese',
  requiredMessages: 'È richiesto un numero di telefono',
  date_of_visit: 'Data',
  hour_of_visit: 'Volta',
  thank_you_for_booking: 'La tua prenotazione',
  no_free_hours: 'Nessuna fascia oraria disponibile nel giorno selezionato.',
  any_person: 'Scelta casuale',
  password_changed: 'La tua password è stata modificata',
  incorrect_old_password: 'La vecchia password non è corretta',
  error404: 'Errore 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: 'Scusate! Questa pagina è stata temporaneamente disabilitata.',
  profile_update_success: 'I tuoi dati sono stati salvati.',
  form_is_invalid: 'Il modulo contiene errori. Si prega di controllare i campi evidenziati e riprovare.',
  search: 'Ricerca',
  pay_online_button_title: 'Paga in linea',
  account_not_activated_info: "L'indirizzo e-mail non è stato confermato. Conferma il tuo indirizzo e-mail facendo clic sul collegamento nel messaggio inviato.",
  paid: 'Pagato',
  payments: 'Pagamenti',
  payment_service: 'Servizio',
  payment_date: 'Data',
  payment_price: 'Prezzo',
  payment_success_title: 'Il pagamento è andato a buon fine, grazie!',
  payment_fail_title: 'Il pagamento non è riuscito, riprova.',
  pay_again: 'Paga di nuovo',
  newsletter_subscribe_success: 'Grazie! Il tuo indirizzo email è stato confermato.',
  newsletter_subscribe_fail: "Si è verificato un problema durante l'attivazione del tuo indirizzo email. Sembra che l'indirizzo email sia già attivo o che il link non sia più valido.",
  newsletter_send_again: 'Invia di nuovo',
  newsletter_send_again_success: 'Il link di attivazione è stato inviato nuovamente.',
  send_again_fail: 'Si è verificato un problema durante la generazione di un nuovo link di attivazione, forse il tuo indirizzo email è già attivo o hai appena generato il link.',
  newsletter_email_is_used: "L'indirizzo email è già presente nell'elenco, se l'indirizzo non è stato confermato possiamo inviare nuovamente il link di attivazione.",
  newsletter_unsubscribe_success: 'Grazie! Il tuo indirizzo email è stato rimosso.',
  newsletter_unsubscribe_fail: "Si è verificato un problema durante l'eliminazione dell'indirizzo e-mail. Sembra che l'indirizzo email sia già stato eliminato o il collegamento sia scaduto.",
  booking_payments: 'Prenotazioni',
  simple_store_product_transactions: 'Prodotti',
  payment_type_select_box: 'Seleziona il tipo di pagamento',
  payment_description: 'Descrizione',
  add_to_cart: 'Aggiungi al carrello',
  remove_from_cart: 'Rimuovere dal carrello',
  save: 'Salva',
  bookings_cart_summary: 'Riepilogo prenotazione',
  today: 'In data odierna',
  fill_booking_details_title: 'Inserisci i dettagli',
  create_an_account_question: "Crea un account e invia la password all'indirizzo e-mail",
  value_is_incorrect: 'Questo valore non è valido.',
  first_available_date: 'Fasce orarie disponibili su',
  service_location_1: 'Indirizzo',
  service_location_2: 'Telefono',
  service_location_3: 'Google Meet',
  service_location_4: 'Altro',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Posizione',
  booking_not_found: 'Prenotazione non trovata.',
  booking_canceled: 'La prenotazione è stata cancellata.',
  booking_canceled_fail: 'Questa prenotazione non può essere cancellata.',
  day: 'Giorno',
  week: 'Settimana',
  month: 'Mese',
  year: 'Anno',
  booking_plural_1: 'Prenotazione',
  booking_plural_2: 'Prenotazioni',
  booking_plural_3: 'Prenotazioni',
  booking_plural_4: 'Prenotazioni',
  booking_plural_5: 'Prenotazioni',
  day_plural_1: 'Giorno',
  day_plural_2: 'Giorni',
  day_plural_3: 'Giorni',
  day_plural_4: 'Giorni',
  day_plural_5: 'Giorni',
  week_plural_1: 'Settimana',
  week_plural_2: 'Settimane',
  week_plural_3: 'Settimane',
  week_plural_4: 'Settimane',
  week_plural_5: 'Settimane',
  month_plural_1: 'Mese',
  month_plural_2: 'Mesi',
  month_plural_3: 'Mesi',
  month_plural_4: 'Mesi',
  month_plural_5: 'Mesi',
  year_plural_1: 'Anno',
  year_plural_2: 'Anni',
  year_plural_3: 'Anni',
  year_plural_4: 'Anni',
  year_plural_5: 'Anni',
  tax: 'partita Iva',
  tax_prefix: 'Prefisso',
  get_company_data: 'Ricerca',
  get_company_data_failed: 'Impossibile scaricare i dati.',
  company_name: 'Nome della ditta',
  street_and_number: 'via e numero',
  postcode: 'Codice Postale',
  city: 'Città',
  country: 'Paese',
  subscribe: 'sottoscrivi',
  recurrent_fee_info: 'Fatturato ogni',
  one_time_fee_info: 'Tariffa una tantum.',
  new_account_title: 'Creare un nuovo account',
  payment_data_title: 'dettagli di pagamento',
  billing_data_checkbox_question: 'Dettagli di fatturazione',
  confirmation_modal_header: "L'azione richiede conferma",
  confirmation_modal_warning: 'Sei sicuro di voler continuare?',
  no_way_to_undo_action: 'Questa azione non può essere annullata!',
  delete_payment_method: 'Rimuovi la carta',
  add_payment_method: 'Aggiungi carta',
  fill_card_details: 'Completa i dettagli del pagamento.',
  subscriptions: 'Abbonamenti',
  subscription: 'Sottoscrizione',
  invoice_number: 'Fattura',
  number: 'Numero',
  status: 'Stato',
  details: 'Particolari',
  created_at: 'Creato',
  subscription_status_initiated: 'Non pagato',
  subscription_status_active: 'Attivo',
  subscription_status_inactive: 'Non attivo',
  subscription_status_canceled: 'Annullato',
  ends_at: 'Finisce a',
  ends_at_default: 'Valido fino alla fine del periodo di fatturazione',
  select_subscription_to_pay: 'Seleziona un abbonamento da pagare.',
  pay_with_subscription: 'Paga con abbonamento',
  bookings_paid_by_subscription_success_message: 'Pagato',
  bookings_not_paid_by_subscription_error_message: "Non è stato possibile pagare la prenotazione con l'abbonamento selezionato.",
  stripe: 'Stripe',
  provider_tag: 'Metodo',
  card: 'Carta',
  cash: 'Contanti',
  transfer: 'Trasferimento',
  promo_code_label: 'Codice di sconto',
  promo_code_invalid_message: 'Il codice sconto fornito non è valido.',
  payment_method_setup_error: 'Si è verificato un problema con la tua carta. La tua banca ha rifiutato di autorizzare la carta per transazioni successive. Si prega di contattare la propria banca.',
  active_subscription_missing: 'La prenotazione di questo servizio richiede un abbonamento attivo. Sfortunatamente, non hai alcun abbonamento da utilizzare.',
  area_code: 'Prefisso',
  usage: 'Utilizzo',
  create_account_in_stripe_failed: "L'operatore di pagamento ha rifiutato i dati dell'abbonamento. Si prega di verificare la correttezza del modulo (codice fiscale, numero di telefono, indirizzo e-mail).",
  additional_auth_required: "La tua banca richiede un'autorizzazione aggiuntiva",
  general_payment_error: 'Si è verificato un problema con il tuo metodo di pagamento. Forse la tua carta non supporta gli abbonamenti. Contatta la tua banca o scegli un metodo di pagamento diverso.',
  subscription_set_successfully: 'Abbonamento impostato correttamente.',
  booking_created: 'Nuova prenotazione',
  booking_updated: 'Cambio di prenotazione',
  booking_deleted: 'Cancellazione di una prenotazione',
  booking_reminder: 'Promemoria prenotazione',
  email_notifications: 'Notifiche di posta elettronica',
  sms_notifications: 'Notifiche SMS',
  save_user_preferences: 'Salva le preferenze',
  free_spots: 'Macchie a sinistra',
  no: 'No',
  yes: 'sì',
  p24_inactive: "L'operatore di pagamento Przelewy24 non può essere utilizzato. Si prega di contattare il proprio operatore di pagamento.",
  stripe_customer_invalid: 'ID cliente Stripe non valido. Il metodo di pagamento non può essere allegato al cliente.',
  stripe_resource_invalid: "Il prodotto selezionato non è disponibile. Si prega di verificare la disponibilità del prodotto con l'operatore di pagamento.",
  account_blocked_message: "Il tuo account è inattivo. Si prega di contattare l'amministratore.",
  time_zone: 'Fuso orario',
  no_spots_left: 'Hai raggiunto il numero massimo di posti disponibili.',
  employee_auth_required: "Accedi al tuo account o utilizza un indirizzo email diverso. Gli account dei dipendenti richiedono l'accesso.",
  subpage_auth_required: 'Accedi al tuo account per visualizzare la scheda.',
  gross: 'Importo lordo.',
  subscription_login_info: 'Hai già un account? Accedi qui sotto per inserire i tuoi dati.',
  change_date_time: 'Riprogrammare',
  promo_code_checkbox: 'Ho un codice sconto',
  booking_promo_code_label: 'Inserisci il codice sconto',
  select_hour: 'Scegli il tempo',
  id: 'Id',
  booking_rescheduled: 'La tua prenotazione è stata modificata.',
  booking_rescheduled_fail: 'Questa prenotazione non può essere modificata.',
  max_user_bookings_error: 'Hai superato il numero massimo di prenotazioni. Scegli un altro servizio o contattaci.',
  in_total: 'Totale',
  company: 'Azienda',
  individual: 'Persona riservata',
  bulk_payment: 'Pagamento in blocco',
  make_bulk_payment: 'Pagamento in blocco',
  simple_store_product_confirmation_title: 'Compila il modulo di acquisto',
  select_service_type: 'Seleziona un\'opzione di servizio',
  add_to_calendar_button_label: 'Aggiungi al calendario',
  login_to_use_service_with_subscription: 'Accedi al tuo account per prenotare un appuntamento per questo servizio.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'La data scelta non è disponibile. Si prega di scegliere un\'altra data libera. In caso di prenotazione di più date, assicurarsi che le date non si escludano a vicenda.',
  select_location_calendar_warning: 'Seleziona una posizione per caricare i tempi disponibili.',
  selectable_location_online_title: 'Online',
  change: 'Cambia',
  show_less_hours_button: 'Mostra meno ore',
  show_more_hours_button: 'Mostra più ore',
  add_another_button_title: 'Aggiungi un altro',
  close: 'Chiudi',
  selected_time_slots: 'Fasce orarie selezionate',
  select_location: 'Seleziona località',
  captcha_invalid_error_notification: 'La verifica captcha non è valida. Prova a ricaricare la pagina se il problema persiste.',
  verify_code_incorrect_error: 'Il codice inserito non è corretto.',
  verify_code_too_many_requests: 'Al momento non possiamo generare un nuovo codice. Riprova più tardi o utilizza il codice che ti abbiamo già inviato.',
  send_again_button_title: 'Invia di nuovo',
  verify_title: 'Verifica',
  verify_code_title: 'Inserisci il codice di verifica',
  verify_code_description: 'Abbiamo inviato un codice di verifica di 6 cifre al tuo indirizzo e-mail. Si prega di inserirlo qui sotto:',
  verification_invalid_error_notification: 'Verifica non valida. Riprova più tardi.',
  too_many_requests: 'Ops! Stai agendo un po\' troppo velocemente. Rallenta e riprova tra un attimo.'
}
